var _sentryCollisionFreeGlobalObject = typeof window === "undefined" ? global : window;
_sentryCollisionFreeGlobalObject["__sentryRewritesTunnelPath__"] = undefined;
_sentryCollisionFreeGlobalObject["SENTRY_RELEASE"] = {"id":"FQuQA__VbANyc-ALqcNUK"};
_sentryCollisionFreeGlobalObject["__rewriteFramesAssetPrefixPath__"] = "";

import * as Sentry from '@sentry/nextjs';

const isSentryEnable = process.env.ENABLE_SENTRY === 'on';

if (isSentryEnable) {
  Sentry.init({
    environment: process.env.APP_CONFIG_ENV,
    dsn: process.env.SENTRY_DSN,
    // Replay may only be enabled for the client-side
    integrations: [new Sentry.BrowserTracing(), new Sentry.Replay()],

    // Capture Replay for 10% of all sessions,
    // plus for 100% of sessions with an error
    replaysSessionSampleRate: 0.1,
    replaysOnErrorSampleRate: 1.0,

    // Note: if you want to override the automatic release value, do not set a
    // `release` value here - use the environment variable `SENTRY_RELEASE`, so
    // that it will also get attached to your source maps
  });
}
